import { logisticsService } from '@/api';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { DeliveryModeEnum, LogisticsOrderStatusEnum, LogisticsStatusEnum } from '@/resource/enum';
import { LogisticsDetail, LogisticsTrack } from '@/resource/model';
import { LogisticsProductList } from '@/resource/model/logistics-management/logistics';
import { debounce, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
@Component({
  name: 'LogisticsDetails'
})
export default class LogisticsDetails extends mixins(PagingMixin) {
  public logistics: LogisticsDetail | null = null;

  public id = Number(this.$route.query.id);

  public mapMode = false;

  public routeInfo: {
    no: string;
    from?: string;
    to?: string;
    status: number;
    arrivalTime: string;
    trailUrl: string;
  } | null = null;

  public timeline: Array<any> = [];

  public tableOption: OsTableOption<LogisticsProductList> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<LogisticsProductList>> = [
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'prepressPicture',
      label: 'projectProduct.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'projectProduct.deliveryNumber',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as LogisticsProductList).finishWidth} × ${(row as LogisticsProductList).finishHeight}`;
      }
    }
  ];

  public get deliveryMode(): string {
    return translation(`deliveryMode.${DeliveryModeEnum[this.logistics!.sendMethod]}`);
  }

  /**
   * 关闭原因
   */
  public get isShowCloseReason(): boolean {
    if (LogisticsOrderStatusEnum.closed === this.logistics?.status) {
      return true;
    }
    return false;
  }

  public get statusName(): string {
    if (!LogisticsOrderStatusEnum[this.logistics!.status]) {
      return translation('common.unKnownStatus');
    }
    return translation(`logisticsOrderStatus.${LogisticsOrderStatusEnum[this.logistics!.status]}`);
  }
  public get logisticsStatusName(): string {
    if (!LogisticsStatusEnum[this.logistics!.logisticsStatus]) {
      return translation('common.unKnownStatus');
    }
    return translation(`logisticsStatus.${LogisticsStatusEnum[this.logistics!.logisticsStatus]}`);
  }

  /**
   * 是否第三方物流发货
   */
  public get isTPL(): boolean {
    return this.logistics?.sendMethod === DeliveryModeEnum.TPL;
  }

  /**
   * 物流单是否已发货
   */
  public get isDelivered(): boolean {
    return this.logistics?.status !== LogisticsOrderStatusEnum.new;
  }

  /**
   * 物流状态是否完成
   */
  public get isComplete(): boolean {
    return (
      this.logistics?.status === LogisticsOrderStatusEnum.received ||
      this.logistics?.status === LogisticsOrderStatusEnum.closed
    );
  }

  /**
   * 发货地址
   */
  public get senderAddress(): string {
    if (this.logistics) {
      return `${this.logistics.sendProvince ?? ''}${this.logistics.sendCity ?? ''}${this.logistics.sendDistrict ??
        ''}${this.logistics.senderAddress ?? ''}`;
    }
    return '';
  }

  /**
   * 收货地址
   */
  public get receivingAddress(): string {
    if (this.logistics) {
      return `${this.logistics.receivingProvince ?? ''}${this.logistics.receivingCity ?? ''}${this.logistics
        .receivingDistrict ?? ''}${this.logistics.receivingAddress ?? ''}`;
    }
    return '';
  }

  public get showLogisticTrack(): boolean {
    return this.isDelivered && this.isTPL;
  }

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.loadDetails();
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.id = Number(this.$route.query.id);
      this.loadDetails();
    }
  }

  public initLogisticsTrack(logisticsTrack?: LogisticsTrack): void {
    if (!logisticsTrack) {
      return;
    }
    this.timeline =
      logisticsTrack.data?.map(x => {
        return {
          content: x.context,
          timestamp: x.time,
          size: 'normal',
          type: 'primary',
          placement: 'top'
        };
      }) || [];
    this.routeInfo = {
      no: logisticsTrack.nu,
      from: '',
      to: '',
      arrivalTime: logisticsTrack.arrivalTime?.substring(0, 10),
      trailUrl: logisticsTrack.trailUrl,
      status: Number(logisticsTrack.state)
    };
    if (logisticsTrack.routeInfo) {
      this.routeInfo.from = logisticsTrack.routeInfo.from.name;
      this.routeInfo.to = logisticsTrack.routeInfo.to.name;
    }
  }
  @debounce()
  private getlogisticsInfo(): void {
    logisticsService
      .getById(this.id)
      .then(res => {
        this.handelInfoData(res);
      })
      .catch(error => {
        messageError(error);
      });
  }
  @debounce()
  private getLogisticsProducts(): void {
    this.tableOption.loading = true;
    logisticsService
      .getLogisticsProducts(this.id, this.paging)
      .then(res => {
        this.totalData = res.total;
        this.tableOption.data = res.data || [];
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 更新物流信息
   */
  private updateLogistics(): void {
    logisticsService
      .refreshTrack(this.id)
      .then(res => {
        if (res) {
          this.handelInfoData(res);
        } else {
          Message.info(translation('logistics.noLogisticsTrack'));
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private handelInfoData(res: LogisticsDetail): void {
    res.price = (res.price?.toFixed(2) as any) as number;
    this.logistics = res;
    this.resetMapData();
    const logisticsTrack: LogisticsTrack = JSON.parse(res.logisticsTrack);
    // 如果是已发货且是第三方物流发货，加载物流轨迹信息
    if (this.isDelivered && this.isTPL) {
      this.initLogisticsTrack(logisticsTrack);
    }
  }

  private resetMapData(): void {
    this.mapMode = false;
    this.timeline = [];
    this.routeInfo = null;
  }
  private loadDetails(): void {
    this.getlogisticsInfo();
    this.getLogisticsProducts();
  }
}
