import { render, staticRenderFns } from "./logistics-details.vue?vue&type=template&id=afde5bf8&scoped=true&"
import script from "./logistics-details.ts?vue&type=script&lang=ts&"
export * from "./logistics-details.ts?vue&type=script&lang=ts&"
import style0 from "./logistics-details.scoped.scss?vue&type=style&index=0&id=afde5bf8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afde5bf8",
  null
  
)

export default component.exports